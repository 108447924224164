@use "variables" as *;

.artworkItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  position: relative;
  max-height: 400px;
  height: 100%;
  &-info {
    height: 30%;
    text-align: center;
  }

  &-title {
    font-weight: bold;
  }

  &-classification {
    font-style: italic;
  }
  &-artist,
  &-title,
  &-classification {
    display: block;
  }

  &-thumbnail {
    height: 100%;
    max-height: 250px;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  &-fav {
    position: absolute;
    top: -$accent_width * 3;
    right: $card_padding_x;
    z-index: 1;
  }

  &-detailsButton {
    @include t4;
    height: fit-content;
    position: relative;
  }
}
