.themeChanger {
  height: 100%;
  aspect-ratio: 1;
  width: fit-content;
  svg {
    display: block;
    margin: auto;
    width: 80%;
    height: 80%;
  }
}
