@use "variables" as *;

.siteTitle {
  @include t2;
  border: 1px solid var(--primary-text-color);
  padding: 12px;
  font-family: monospace;
  letter-spacing: 6px;
  height: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  @media only screen and (max-width: 620px) {
    @include t4;
    letter-spacing: 3px;
    font-weight: 600;
  }
}

.color1 {
  color: var(--accent_color_1);
}

.color2 {
  color: var(--accent_color_2);
}
.color3 {
  color: var(--accent_color_3);
}
