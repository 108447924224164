@use "variables" as *;

.t1,
h1 {
  @include t1;
}

.t2,
h2 {
  @include t2;
}

.t3,
h3 {
  @include t3;
}

.t4,
h4 {
  @include t4;
}

.t5,
h5 {
  @include t5;
}

hr {
  color: var(--primary-text-color);
}
