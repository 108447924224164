@use "variables" as *;

.card {
  position: relative;
  $border-radius: 2px;
  box-shadow: 5px 5px 7px var(--box_shadow_color);
  border-radius: $border-radius;
  padding: $accent_width;
  background: var(--accent_color);
  &-content {
    position: relative;
    border-radius: $border-radius;
    background-color: var(--component_color);
    width: 100%;
    height: 100%;
    padding: $card_padding_y $card_padding_x;
  }
}
