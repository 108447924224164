@use "variables" as *;

.artworkList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: $padding_primary;
  grid-row-gap: $padding_primary;

  @media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 820px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
