$width: 30px;
$height: 50px;

.buttonFavourite {
  position: relative;
  height: height;
  width: $width;
  text-align: center;
  --baseColor: rgb(155, 0, 0);
  --starColor: transparent;
  cursor: pointer;
  &:hover {
    --baseColor: rgb(190, 0, 0);
  }

  svg {
    height: height;
    width: $width;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }
}

.enabled {
  --starColor: yellow;
}

.buttonFavouriteWithText {
  margin-top: 24px;
  display: flex;
  align-items: center;
  height: fit-content;
  gap: 12px;
  svg {
    height: 30px;
  }
  .buttonFavourite:hover {
    all: unset;
    --baseColor: rgb(155, 0, 0);
  }
}
